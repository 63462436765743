<template>
  <div class="home">
    <eden-page-header :title="'Settings'" />
    <el-tabs v-model="tab" @tab-click="updateRouteQuery">
      <el-tab-pane label="Profile" name="profile">
        <profile-settings />
      </el-tab-pane>
      <el-tab-pane label="Security" name="security">
        <security-settings />
      </el-tab-pane>
      <el-tab-pane label="Members" name="members">
        <members />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProfileSettings from "@/components/Settings/ProfileSettings.vue";
import SecuritySettings from "@/components/Settings/SecuritySettings.vue";
import Members from "@/components/Settings/Members/Members.vue";

export default {
  name: "Settings",
  components: {
    ProfileSettings,
    SecuritySettings,
    Members,
  },
  data() {
    return {
      tab: "profile",
    };
  },
  created() {
    const { tab } = this.$route.query;
    if (tab) {
      this.tab = tab;
    } else {
      this.tab = "profile";
    }
  },
  methods: {
    updateRouteQuery(tab) {
      const currentTab = this.$route.query.tab;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "settings.index",
        query: { tab: tab.name },
      });
    },
  },
};
</script>
