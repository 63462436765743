<template>
  <div class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section title="Security" subtitle="Change password">
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Enter current password"
                prop="current_pwd"
                :rules="validateField()"
              >
                <el-input type="password" v-model="form.current_pwd" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Enter new password"
                prop="new_pwd"
                :rules="rules.new_pwd"
              >
                <el-input type="password" v-model="form.new_pwd" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Re-enter new password"
                prop="confirm_new_pwd"
                :rules="rules.confirm_new_pwd"
              >
                <el-input type="password" v-model="form.confirm_new_pwd" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>

      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions justify-end">
            <el-button
              type="primary"
              :loading="changing"
              @click="updatePassword"
              >Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import profile from "@/requests/settings/profile";

export default {
  name: "SecuritySettings",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Enter your new password"));
      } else if (value === this.form.current_pwd) {
        callback(new Error("New password can't be old password"));
      } else {
        callback();
      }
    };
    const confirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Re-enter your password"));
      } else if (value !== this.form.new_pwd) {
        callback(new Error("Passwords do not match"));
      } else {
        callback();
      }
    };
    return {
      form: {
        current_pwd: "",
        new_pwd: "",
        confirm_new_pwd: "",
      },
      rules: {
        new_pwd: [{ validator: validatePassword, trigger: "blur" }],
        confirm_new_pwd: [{ validator: confirmPassword, trigger: "blur" }],
      },
      changing: false,
    };
  },
  computed: {
    userId() {
      return this.$store.getters.user.logged_in_userId;
    },
  },
  methods: {
    updatePassword() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        const payload = {
          user_id: this.userId,
          current_pwd: this.form.current_pwd,
          new_pwd: this.form.new_pwd,
        };

        this.changing = true;
        profile
          .password(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$refs.form.resetFields();
              this.changing = false;
            }
          })
          .catch((error) => {
            this.changing = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
            this.$refs.form.resetFields();
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-page-form {
  width: 60% !important;
  min-width: 300px;
  padding: 20px !important;
}
</style>
