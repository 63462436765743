import axios from "axios";

export default {
  get(id) {
    return axios.get(`lighthouse/settings/account/${id}`);
  },

  update(id, payload) {
    return axios.put(`lighthouse/settings/account/${id} `, payload);
  },

  password(payload) {
    return axios.post("lighthouse/settings/reset_password", payload);
  },

  delete(email) {
    return axios.delete(`customer/${email}`);
  },
};
