<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column is-align-center">
              <eden-image-upload
                :image-url.sync="form.avatar"
                :image-folder="'user'"
              />
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Personal"
        subtitle="Update your personal details"
      >
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="First name"
                prop="first_name"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.first_name"
                  placeholder="Enter first name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Last name"
                prop="last_name"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.last_name"
                  placeholder="Enter last name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Email" prop="email" :rules="validateEmail()">
                <el-input
                  type="text"
                  v-model="form.email"
                  placeholder="you@mail.com"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item
                label="Phone number"
                prop="phone_number"
                :rules="validatePhone()"
              >
                <el-input
                  type="text"
                  v-model="form.phone_number"
                  v-number
                  :maxlength="11"
                  placeholder="0812 345 6789"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Other"
        subtitle="Enter some extra information about you"
      >
        <template slot="form-fields">
          <el-row type="flex" class="is-flex-wrap" ref="preferences">
            <el-col :md="24">
              <el-form-item label="Bio" prop="bio" :rules="validateField()">
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.bio"
                  placeholder="Enter your bio"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="is-flex-wrap" ref="preferences">
            <el-col :md="24">
              <el-form-item
                label="Address"
                prop="home_address"
                :rules="validateField()"
              >
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.home_address"
                  placeholder="Enter your full address"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>

      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions justify-end">
            <el-button type="primary" :loading="updating" @click="updateProfile"
              >Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import profile from "@/requests/settings/profile";

export default {
  name: "ProfileSettings",
  data() {
    return {
      loading: false,
      updating: false,
      form: {
        avatar: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        bio: "",
        home_address: "",
      },
    };
  },
  created() {
    this.getProfile();
  },
  computed: {
    userId() {
      return this.$store.getters.user.logged_in_userId;
    },
  },
  methods: {
    getProfile() {
      this.loading = true;
      profile
        .get(this.userId)
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data;

            Object.keys(this.form).forEach((key) => {
              this.form[key] = data[key] || "";
            });

            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateProfile() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;
        profile
          .update(this.userId, this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
