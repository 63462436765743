<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template v-if="allowAccessFor(['admin', 'operations'])" slot="actions">
        <el-button type="primary" @click="showMemberAdd = true">
          Add New Member
        </el-button>
      </template>
    </eden-table-actions>

    <template v-if="loading">Loading...</template>
    <template v-else>
      <el-table :data="pageData">
        <el-table-column width="80">
          <template slot-scope="scope">
            <img
              :style="{
                height: '14px',
              }"
              :src="getCountryFlag(scope.row.domicile_location)"
              :alt="scope.row.domicile_location"
            />
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Name</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm"> {{ formatName(scope.row.name) }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span class="eden-table-header">
              Email
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm text-grey-tertiary">{{
              scope.row.email.toLowerCase()
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="slack_id">
          <template slot="header">
            <span class="eden-table-header">
              Slack Id
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm text-grey-tertiary">{{
              scope.row.slack_id || "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="role">
          <template slot="header">
            <span class="eden-table-header">
              Role
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm text-grey-tertiary">{{
              roles()[scope.row.role]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="access" width="120">
          <template slot="header">
            <span class="eden-table-header">
              Access
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.invite_completed === 'pending'">
              <el-tag type="warning">Pending Invite</el-tag>
            </div>
            <div v-else>
              <el-tag v-if="scope.row.access" type="success">Active</el-tag>
              <el-tag v-else type="danger">Disabled</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="allowAccessFor(['admin'])" width="80">
          <template v-slot="scope">
            <member-actions :member.sync="scope.row" @get="getMembers" />
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="showPagination"
        :from="from"
        :to="to"
        :total="total"
        :current-page.sync="page"
      />
    </template>
    <member-add :show.sync="showMemberAdd" />
  </div>
</template>

<script>
import MemberActions from "@/components/Settings/Members/Member/MemberActions";

import * as actions from "@/store/action-types";
import members from "@/requests/settings/members";
import MemberAdd from "@/components/Settings/Members/MemberAdd";

export default {
  name: "Members",
  components: { MemberAdd, MemberActions },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      showMemberAdd: false,
    };
  },
  computed: {
    members() {
      return this.$store.getters.members;
    },
    from() {
      return this.members.pages[this.page].from;
    },
    to() {
      return this.members.pages[this.page].to;
    },
    total() {
      return this.members.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} Members`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.members.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getMembers();
      }
    },
  },
  created() {
    const pageFetched = !!this.members.pages[this.page];
    if (pageFetched) {
      this.setPageData();
    } else {
      this.getMembers();
    }
  },
  methods: {
    getMembers() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_MEMBERS, this.page)
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      members
        .search(query)
        .then((response) => {
          this.pageData = response.data.data;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.members.pages[this.page].data;
      this.showPagination = true;
    },
    removeMember(index) {
      this.pageData.splice(index, 1);
    },
  },
};
</script>
