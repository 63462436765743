<template>
  <el-dialog
    title="Add New Member"
    :visible.sync="setShow"
    @close="close"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex" class="is-flex-wrap" :gutter="15">
        <el-col :md="12">
          <el-form-item
            label="First name"
            prop="first_name"
            :rules="validateField()"
          >
            <el-input type="text" v-model="form.first_name" />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Last name"
            prop="last_name"
            :rules="validateField()"
          >
            <el-input type="text" v-model="form.last_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="is-flex-wrap" :gutter="15">
        <el-col :md="12">
          <el-form-item label="Email" prop="email" :rules="validateEmail()">
            <el-input type="text" v-model="form.email" />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Phone number"
            prop="phoneNo"
            :rules="validateField()"
          >
            <eden-phone-input :input.sync="form.phoneNo" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="is-flex-wrap">
        <el-col :md="24">
          <el-form-item label="Address" prop="address" :rules="validateField()">
            <el-input
              type="textarea"
              rows="4"
              v-model="form.address"
              placeholder="Enter your full address"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="is-flex-wrap">
        <el-col :md="24">
          <el-form-item label="Role" prop="role" :rules="validateField()">
            <el-select v-model="form.role" placeholder="Select role">
              <el-option
                v-for="(value, key, index) in allRoles"
                :key="index"
                :label="value"
                :value="key"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="close">Cancel</el-button>
      <el-button type="primary" :loading="adding" @click="add"
        >Add Member</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import members from "@/requests/settings/members";

export default {
  name: "MemberAdd",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      adding: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        address: "",
        avatar:
          "https://res.cloudinary.com/eden-life-inc/image/upload/v1593165834/lighthouse/avatar-placeholder_uxleqd.png",
        phoneNo: "",
      },
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    allRoles() {
      let availableRoles = this.roles();
      if (this.allowAccessFor(["superadmin"])) {
        return availableRoles;
      }
      delete availableRoles.superadmin;
      delete availableRoles.gardener;
      delete availableRoles.gardener_pro;
      return availableRoles;
    },
  },
  methods: {
    close() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;

        members
          .add(this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.adding = false;
              this.close();
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
