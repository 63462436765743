<template>
  <div>
    <el-dropdown @command="command">
      <span class="el-dropdown-link more">
        <i class="eden-icon-more"></i>
      </span>
      <el-dropdown-menu slot="dropdown" v-if="allowAccessFor('admin')">
        <el-dropdown-item :command="'email'">Change email</el-dropdown-item>
        <el-dropdown-item :command="'reset'">Reset password</el-dropdown-item>
        <el-dropdown-item :command="'role'">Change role</el-dropdown-item>
        <template>
          <el-dropdown-item :command="'slack'"
            >Update Slack Id</el-dropdown-item
          >
          <template v-if="member.invite_completed === 'pending'">
            <el-dropdown-item :command="'resend'"
              >Resend Invite</el-dropdown-item
            >
            <el-dropdown-item :command="'link'"
              >Copy Invite Link</el-dropdown-item
            >
          </template>
          <template v-else>
            <el-dropdown-item v-if="member.access" :command="'disable'"
              >Disable access</el-dropdown-item
            >
            <el-dropdown-item v-else :command="'enable'"
              >Enable access</el-dropdown-item
            >
            <el-dropdown-item :command="'reset'"
              >Reset password</el-dropdown-item
            >
          </template>
          <el-dropdown-item :command="'remove'">Remove member</el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <eden-confirm-dialog
      :title="dialog.title"
      :button-text="dialog.button"
      :button-type="dialog.buttonType"
      :button-status.sync="confirmDialog.status"
      :show.sync="confirmDialog.visibility"
      @confirm="setAction"
    >
      <p>
        {{ dialog.message }}
      </p>
    </eden-confirm-dialog>
    <component
      :is="actions.component"
      :show.sync="actions.visibility"
      :email="member.email"
      :id="member.id"
      :role="member.role"
      :slack-id="member.slack_id"
      @success="updateMember"
    />
  </div>
</template>

<script>
import member from "@/requests/settings/members/member";

export default {
  name: "MemberActions",
  props: {
    member: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    MemberRoleChange: () => import("./MemberRoleChange"),
    MemberEmailChange: () => import("./MemberEmailChange"),
    MemberSlackIdChange: () => import("./MemberSlackIdChange"),
  },
  data() {
    return {
      actions: {
        command: "",
        component: "",
        visibility: false,
        email: {
          component: "member-email-change",
          property: "email",
        },
        role: {
          component: "member-role-change",
          property: "role",
        },
        slack: {
          component: "member-slack-id-change",
          property: "slack_id",
        },
      },
      confirmDialog: {
        action: "",
        visibility: false,
        status: false,
      },
    };
  },
  computed: {
    dialog() {
      const dialogs = {
        resend: {
          title: "Resend Invitation",
          button: "Resend invite",
          buttonType: "success",
          message: `Are you sure you want to resend invitation to ${this.member.email}?`,
        },
        reset: {
          title: "Reset password",
          button: "Reset",
          buttonType: "success",
          message: `Are you sure you want to reset ${this.member.name} password?`,
        },
        enable: {
          title: "Enable access",
          button: "Enable access",
          buttonType: "success",
          message: `Are you sure you want to enable ${this.member.name} access?`,
        },
        disable: {
          title: "Disable access",
          button: "Disable access",
          buttonType: "danger",
          message: `Are you sure you want to disable ${this.member.name} access?`,
        },
        remove: {
          title: "Remove team member",
          button: "Remove",
          buttonType: "danger",
          message: `Are you sure you want to remove ${this.member.name}?`,
        },
      };
      return dialogs[this.confirmDialog.action]
        ? dialogs[this.confirmDialog.action]
        : dialogs.enable;
    },
    setMember: {
      get() {
        return this.member;
      },
      set(value) {
        this.$emit("update:member", value);
      },
    },
  },
  methods: {
    command(command) {
      switch (command) {
        case "email":
          this.setActions(command);
          break;
        case "role":
          this.setActions(command);
          break;
        case "slack":
          this.setActions(command);
          break;
        case "link":
          this.copyLink();
          break;
        default:
          this.confirmDialog.action = command;
          this.confirmDialog.visibility = true;
          break;
      }
    },
    setActions(command) {
      this.actions.component = this.actions[command].component;
      this.actions.command = command;
      setTimeout(() => {
        this.actions.visibility = true;
      }, 100);
    },
    setAction() {
      this.confirmDialog.status = true;
      switch (this.confirmDialog.action) {
        case "reset":
          this.resetPassword();
          break;
        case "enable":
          this.changeAccess(1);
          break;
        case "disable":
          this.changeAccess(0);
          break;
        case "resend":
          this.resendInvite();
          break;
        case "remove":
          this.removeMember();
          break;
      }
    },
    resetPassword() {
      member
        .reset(this.member.id)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.closeConfirmDialog();
          }
        })
        .catch();
    },
    changeAccess(access) {
      member
        .access(this.member.id, { access })
        .then((response) => {
          if (response.data.status) {
            this.setMember.access = access;
            this.$message.success(response.data.message);
            this.closeConfirmDialog();
          }
        })
        .catch();
    },
    resendInvite() {
      const payload = {
        email: this.member.email,
        user_id: this.member.id,
      };

      member
        .invite(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.closeConfirmDialog();
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.closeConfirmDialog();
        });
    },
    copyLink() {
      const APP_URL = process.env.VUE_APP_URL;
      const link = `${APP_URL}invite/account/${this.member.id}`;
      navigator.clipboard.writeText(link);
      this.$message.success("Link copied!");
    },
    removeMember() {
      const payload = {
        email: this.member.email,
        reason: "Member account delete",
      };
      member
        .delete(payload.email, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("get");
            this.closeConfirmDialog();
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.closeConfirmDialog();
        });
    },
    closeConfirmDialog() {
      this.confirmDialog.status = false;
      this.confirmDialog.visibility = false;
    },
    updateMember(value) {
      const property = this.actions[this.actions.command].property;
      this.setMember[property] = value;
    },
  },
};
</script>
